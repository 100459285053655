<template>
  <div>
    <div class="card-tm">
      <div class="container container-row">
        <div class="row gy-3">
          <div class="col-md-12">
            <TopHeading :data="topHeading" />
          </div>
          <div class="col-12">
            <div class="card shadow-sm">
              <div class="card-body">
                <div class="table-container table-height table-responsive custom-scrollbar" ref="tableContainer">
                  <table class="table table-borderless align-middle" id="table-list" ref="tablelist">
                    <thead class="">
                      <tr class="fs-13 fw-500 text-capitalize font-secondary m-0 text-nowrap">
                        <th scope="col" v-for="(item, index) in tableHeading" :key="index">{{ item }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="skeletonLoad">
                        <tr class="align-middle" v-for="i in tableHeading.length" :key="i">
                          <td class="text-uppercase" v-for="j in tableHeading.length" :key="j">
                            <VueSkeletonLoader type="react" :width="100" :height="15" animation="wave" color="#adb5bd"
                              wave-color="#fff" :rounded="true" />
                          </td>
                        </tr>
                      </template>
                      <template v-if="ticketlist.length >= 1 && !skeletonLoad">
                        <tr class="border-0" v-for="(list, index) in ticketlist" :key="index">
                          <td>
                            <h6 class="subtitle">{{ index + 1 }}</h6>
                          </td>
                          <td>
                            <div class="d-flex gap-2 align-items-center">
                              <!-- <img src="https://tradebit.io/backend/public/currency_images/tether.png" width="23"> -->
                              <h6 class="fs-15 m-0 fw-500">{{ list.category.name }}</h6>
                            </div>
                          </td>
                          <td>
                            <h6 class="fs-14 fw-600 m-0">{{ list.title }}</h6>
                          </td>
                          <td>
                            <h6 class="subtitle">{{ list.author_name }}</h6>
                          </td>
                          <td>
                            <h6 class="subtitle">{{ list.author_email }}</h6>
                          </td>
                          <td>
                            <h6 class="subtitle">{{
                              new Date(list.created_at).toLocaleDateString(
                                "en-US",
                                options
                              )
                            }}</h6>
                          </td>
                          <td>
                            <span class="subtitle text-capitalize"><span class="badge text-capitalize"
                                :class="list.status == 'open' ? 'success-badge' : list.status == 'close' ? 'danger-badge' : 'pending-badge'">{{
                                  list.status
                                }}</span></span>
                          </td>
                          <td class="text-center">
                            <button type="button" data-toggle="modal" data-target="#ticket-info"
                              class="badge text-dark border-0" @click="openTicket(list)"><i
                                class="fas fa-eye"></i></button>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                  <div class="col-12 text-center" v-if="loading && !skeletonLoad">
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  <div v-if="ticketlist.length < 1 && !skeletonLoad"
                    class="d-flex border-0 flex-column text-center justify-content-center py-4">
                    <div>
                      <img width="70" :src="require(`@/assets/images/icons/no-record.svg`)" />
                    </div>
                    <span class="fw-500 fs-14">No ticket found</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SupportTicketInfo :ticketInfo="ticketInfo" @callList="callList" />
  </div>
</template>

<script>
import SupportTicketInfo from "@/components/modal/SupportTicketInfo";
import VueSkeletonLoader from "skeleton-loader-vue";
import TopHeading from "@/components/ui/TopHeading.vue";
import ApiClass from "@/api/api";
export default {
  name: 'TicketList',
  components: {
    TopHeading,
    VueSkeletonLoader,
    SupportTicketInfo
  },
  data: () => ({
    topHeading: {
      heading: 'Support Tickets',
      subheading: '',
      buttons: [
        // { name: 'Spot Wallet', link: '/wallet/spot', class: 'text-primary border-primary' },
        // {name:'Deposit', link:'/wallet/deposit', class:'btn-primary text-white'}
      ],
    },
    tableHeading: ["#", "ticket type", "Subject", "name", "email", "created at", "status", "action"],
    ticketInfo: {
      is_open: 0,
      row: [],
      status: null,
      comments: [],
      selected: []
    },
    ticketlist: [],
    loading: false,
    skeletonLoad: true,
    options: {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      // second: "numeric",
    },
  }),

  async mounted() {
    this.loading = true;
    await this.callList()
  },
  methods: {
    async callList() {
      var url = "ticket/get";
      await ApiClass.getRequest(url, true).then((response) => {
        this.ticketlist = response.data.data;
        this.loading = false;
        this.skeletonLoad = false;
      });
    },
    async openTicket(list) {
      await ApiClass.getRequest(`ticket/get/${list.id}`, true).then((response) => {
        this.ticketInfo.selected = list;
        this.ticketInfo.row = response.data.data;
        this.ticketInfo.comments = response.data.data.comments;
        this.ticketInfo.status = response.data.data.status;
        this.ticketInfo.is_open = 1;
      });
    }
  }
};
</script>
<style scoped>
.badge {
  font-size: 12px;
}

.success-badge {
  background-color: #CCFBF1;
}

.danger-badge {
  background-color: #FEE2E2;
}

.pending-badge {
  background-color: #ffdfa1;
}
</style>