<template>
  <div class="card-tm">
   
    <img :src="require(`../assets/refferal/reward_shib.png`)"
                                    class="img-fluid" />
                                  
    
    <!-- body -->
<section class="py-3" id="body-section">
  <div class="container rounded-4 card  py-4" id="body-container">
    <h4 class="fs-5 fw-600">Invite Friends Earn Coins</h4>
          <p class="small">Invite firends to signup using your link you'll get some amount of their earnings -
            and they land on your wallet.</p>
          <div class="col-12 col-lg-6">
            <div class="input-group mt-3">
            <input type="text" class="form-control"  v-model="referral_link" readonly placeholder="Subscribe Newsletter" aria-label=""
              aria-describedby="basic-addon2">
            <span class="input-group-text btn-action border-0 p-2 px-lg-4 py-lg-3" id="basic-addon2" @click="copyLink(referral_link)"><span v-html="copy"></span></span>
          </div>
        </div>
        <p class="my-2 small">Invite must be signup using your link to get rewards</p>
       
    <!-- main tabs -->
    <ul class="nav nav-pills mb-3 main-tabs mt-4 " id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link  active " id="pills-Dashboard-tab" data-bs-toggle="pill"
          data-bs-target="#pills-Dashboard" type="button" role="tab" aria-controls="pills-Dashboard"
          aria-selected="true">Dashboard</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="pills-Referrals-tab" data-bs-toggle="pill"
          data-bs-target="#pills-Referrals" type="button" role="tab" aria-controls="pills-Referrals"
          aria-selected="false">Direct Referrals</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="pills-levelReferrals-tab" data-bs-toggle="pill"
          data-bs-target="#pills-levelReferrals" type="button" role="tab" aria-controls="pills-levelReferrals"
          aria-selected="false">Level Referrals</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="pills-Commission-tab" data-bs-toggle="pill"
          data-bs-target="#pills-Commission" type="button" role="tab" aria-controls="pills-Commission"
          aria-selected="false">Commission</button>
      </li>


    </ul>
    <!-- main tabs content -->
    <div class="tab-content" id="pills-tabContent">

      <!-- dashboard content -->
      <div class="tab-pane fade show active mt-5" id="pills-Dashboard" role="tabpanel"
        aria-labelledby="pills-home-tab" tabindex="0">
        <!-- all today yesterday content -->
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-All" role="tabpanel" aria-labelledby="pills-All-tab"
            tabindex="0">
            <div class="bg-light-yellow rounded-3 p-4">
              <div class="row">
                <div class="col">
                  <p class="mb-2">Your Earnings</p>
                  <h3 class="fw-semibold">{{totalR }} SHIB</h3>
                </div>

                <!-- <div class="col">
                  <p class="mb-2">Friends Who Staked <span class="small badge bg-primary">coming soon</span></p>
                  <h3 class="fw-semibold">0</h3>
                </div> -->

                <div class="col">
                  <p class="mb-2">Welcome Bonus</p>
                  <h3 class="fw-semibold"> {{ welcomeBonus }} SHIB</h3>
                </div>
                <div class="col">
                  <p class="mb-2">Direct Friends</p>
                  <h3 class="fw-semibold">{{totalLink}}</h3>
                </div>
              </div>

           
            </div>
            <div class="text-secondary pt-5">
              <p class="fs-12">* Data update time refers to UTC + 0 time zone. The data maintenance time is 3am - 5am (UTC+0)
                every day. During this period, the calculation of today's data is based on the assets of previous
                day. After maintenance, all data will be displayed properly</p>
              <p class="fs-12">* Statement: due to the complexity of financial data, there might be nuances and delay. Data
                displayed above is for reference only. We sincerely apologize for any inconvenience.</p>
            </div>
          </div>



        </div>
      </div>

      <!-- reffreal content -->
      <div class="tab-pane fade mt-5" id="pills-Referrals" role="tabpanel" aria-labelledby="pills-Referrals-tab"
        tabindex="0">
        <table class="table ">
          <thead >
            <tr>
              <th scope="col" class="text-secondary fw-normal">#</th>
              <th scope="col" class="text-secondary fw-normal">Referee UID</th>
              <th scope="col" class="text-secondary fw-normal">Referee Email Address</th>
              <th scope="col" class="text-secondary fw-normal">Referee Registered</th>
        
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data,index) in refUsers" :key="index">
              <td class="text-secondary">{{index+1}}</td>
              <td class="text-secondary">{{data.username}}</td>
              <td class="text-secondary">{{data.email}}</td>
              <td class="text-secondary">{{new Date(data.email_verified_at).toLocaleString()}}</td>

            </tr>

          </tbody>
        </table>
      </div>

      <div class="tab-pane fade mt-5" id="pills-levelReferrals" role="tabpanel" aria-labelledby="pills-levelReferrals-tab"
        tabindex="0">
        <table class="table ">
          <thead >
            <tr>
              <th scope="col" class="text-secondary fw-normal">#</th>
              <th scope="col" class="text-secondary fw-normal">User UID</th>
              <th scope="col" class="text-secondary fw-normal">User Email Address</th>
              <th scope="col" class="text-secondary fw-normal">Amount SHIB</th>
              <th scope="col" class="text-secondary fw-normal">Level</th>
              <th scope="col" class="text-secondary fw-normal">Date</th>
        
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data,index) in userrefferalUsers" :key="index">
              <td class="text-secondary">{{index+1}}</td>
              <td class="text-secondary">{{data.username}}</td>
              <td class="text-secondary">{{data.email}}</td>
              <td class="text-secondary">{{data.amount}}</td>
              <td class="text-secondary">{{index+1}}</td>
              <td class="text-secondary">{{new Date(data.created_at).toLocaleString()}}</td>

            </tr>

          </tbody>
        </table>
      </div>

      <!-- Commission content -->
      <div class="tab-pane fade" id="pills-Commission" role="tabpanel" aria-labelledby="pills-Commission-tab"
        tabindex="0">
      <Referral_commission/>
      </div>



    </div>
  </div>
</section>
<img :src="require(`../assets/refferal/reward_comission.png`)" class="img-fluid"/>
   
  </div>
</template>

<script>
// import SettingsLayout from "@/layouts/SettingsLayout.vue";
import exactMath from "exact-math"
// import Pagination from "vue-pagination-2";

import ApiClass from "@/api/api";
 import Referral_commission from "./Settings/Referral_commission.vue";
// import ActivityLogVue from './ActivityLog.vue';
// import LoginVue from '../Auth/Login.vue';
// import {
//     required,
//     minLength,
//     sameAs
// } from 'vuelidate/lib/validators';
export default {
  name: "Referral-Link",
  components: {
     Referral_commission
  },
  data() {
    return {
      referral_link: "",
      referral_code: "",
      copy: `<i class="fa-solid fa-copy"></i>`,
      refData: [],
      refUsers: null,
      user: [],
      getToken: false,
      totalLink: 0,
      userrefferalUsers: null,
      welcomeBonus: 0
      ,
    };
  },

  mounted() {
    this.getToken = localStorage.getItem("token") ? true : false;
    console.log(this.getToken);
    this.user = JSON.parse(localStorage.getItem("user"));
    this.referral_link = ApiClass.VUE_DOMAIN + "signup?referral=" + this.user.referral_code;
    this.referral_code = this.user.referral_code;
    this.getToken ? this.callback() : '';
    this.getToken ? this.Welcomecallback() : '';
  },
  computed: {
    totalR() {
      let s = 0;
      this.refData?.map((el) => {
        s = exactMath.add(s, el.amount);
        s = exactMath.add(s, this.welcomeBonus);
      });
      return s;
    }
  },
  methods: {
    async callback() {
      let res = await ApiClass.getRequest(
        "user/getReferrals",
        true
      );
      if (res.data.status_code == 1) {
        this.totalLink = res.data.data.refferal.length;
        this.refUsers = res.data.data.refferal;
        this.userrefferalUsers = res.data.data.userrefferal;
        this.refData = res.data.data.userrefferal.filter(x => x.amount);
      }
    },
    async Welcomecallback() {
      let res = await ApiClass.getRequest(
        "user/getWelcomeBonus",
        true
      );
      if (res.data.status_code == 1) {
        var data = res.data.data;
        this.welcomeBonus = data.amount;
      }
    },

    copyLink(id) {
      var input = document.createElement('input');
      input.setAttribute('value', id);
      input.value = id;
      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, 99999);
      var res = document.execCommand("copy");
      // // var copyText = document.getElementById("referral");
      // var copyText = document.getElementById(id);
      // //    console.log( copyText)
      // copyText.select();
      // copyText.setSelectionRange(0, 99999);
      // var res = document.execCommand("copy");

      this.copy = res ? '<i class="fa-solid fa-circle-check text-white"></i>' : '<i class="fa-solid fa-copy"></i>';

      //   console.log(res)
    },
  },
};
</script>
<style scoped>
.heading {
  font-size: 55px !important;
}
</style>