<template>
  <div>
    <div class="modal" :class="ticketInfo.is_open == 1 ? 'show' : ''" id="ticket-info" tabindex="-1"
      aria-labelledby="exampleModalLabel" aria-hidden="true" v-if="ticketInfo.is_open">
      <div class="modal-dialog modal-dialog-scrollable card-tm modal-dialog-centered">
        <div class="modal-content card">
          <div class="modal-header border-0">
            <h5 class="modal-title">Ticket ({{ ticketInfo.selected.category.name }})</h5>
            <button type="button" class="btn close-btn" @click="close" data-dismiss="modal" aria-label="Close"
              ref="Close">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body p-4">
            <div class="col-md-12">
              <div class="row gy-3" v-if="ticketInfo.comments.length > 0">
                <div class="card-message p-2" v-for="(item, k) in ticketInfo.comments" :key="k">
                  <div class="d-flex mb-2 justify-content-between align-items-center">
                    <h6 class="fs-6 fw-600 m-0">{{ item.user.name }}</h6>
                    <span class="fs-14">{{
                      new Date(item.created_at).toLocaleDateString(
                        "en-US",
                        options
                      )
                    }} </span>
                  </div>
                  <p class="fs-14">{{ item.comment }}</p>
                </div>
              </div>
              <p class="text-center" v-else>No comment found</p>
            </div>
          </div>
          <div class="modal-footer p-2 px-4">
            <div class="col-12">
              <form @submit.prevent="submit">
                <div class="form-group">
                  <label class="form-label">Comment</label>
                  <textarea class="form-control" placeholder="Reply" id="floatingTextarea" rows="2"
                    v-model.trim="$v.form.comment.$model"></textarea>
                  <div class="error" v-if="submitted && !$v.form.comment.required">
                    Comment is required
                  </div>
                </div>
                <div class="form-group mt-3">
                  <button type="submit" class="btn btn-primary w-100">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ApiClass from "@/api/api";
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    ticketInfo: Object,
    callList:Function
  },
  name: "SupportTicketInfo",
  data() {
    return {
      ticketlist: [],
      loading: false,
      form: { comment: null },
      submitted: false,
      options: {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      },
    };
  },
  validations: {
    form: {
      comment: {
        required,
      },
    },
  },
  async mounted() {
    var url = "ticket_type/get";
    await ApiClass.getRequest(url, true).then((response) => {
      this.ticketlist = response.data.data;
      this.form.category_id = response.data.data[0]?.id;
    });
    // this.theModal = new Modal(document.getElementById("support-ticket"));
  },
  methods: {
    close() {
      this.form.comment = null;
      this.$emit('callList')
      this.$refs.Close.click();
    },

    async submit() {
      this.submitted = true;
      if (this.$v.form.$invalid) {
        return;
      }
      this.loading = true
      this.form.ticket_id = this.ticketInfo.selected.id;
      await ApiClass.postRequest("ticket_comment/create", true, this.form).then(
        (response) => {
          if (response.data.status_code == 1) {
            this.$swal({
              toast: true,
              position: "top-end",
              icon: "success",
              title: response.data.message,
              showConfirmButton: false,
              timer: 3000,
            });
            this.form.comment = "";
            this.loading = this.submitted = false;
            return this.close();

          } else {
            this.$swal({
              toast: true,
              position: "top-end",
              icon: "error",
              title: response.data.message,
              showConfirmButton: false,
              timer: 3000,
            });
            this.loading = this.submitted = false;
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.modal-body {
  padding: 2rem;
}

/* .modal {
  background-color: #edf0f2bf;
} */

.modal-header .close-btn {
  border-radius: 50%;
  padding: .2rem .6rem;
}

.modal-content {
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  border: none;
  border-radius: 3px;
}

.modal-header h5 {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  /* color: rgb(72, 81, 86); */
  margin-bottom: 0;
}

.is_dark .modal-header button {
  color: white;
}

button#logout-blank {
  color: rgb(244, 67, 54);
  background: transparent;
  border-color: rgb(244, 67, 54);
  margin-right: 10px;
}

button.btn-close:focus {
  box-shadow: none;
  outline: none;
}

.modal-header {
  background: var(--bg5);
}

.modal {
  z-index: 9999;
}

.card-message {
  background-color: var(--bg5);
  border-radius: .5rem;
}
</style>
