<template>
  <div>
  <section class="min-vh-100 d-flex flex-column bg">
        <div class="container my-auto">
          <div class="row">
            <div class="col-12 text-center mx-auto">
            <img src="/assets/images/comingsoon.png" class="w-50"/>
            </div>
          </div>
        </div>
      
    </section>
  </div>
</template>

<script>
export default {
  name: "Coming",
};
</script>



<style scoped>
.coming-bg {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
}
</style>